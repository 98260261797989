import React from "react";
import { Dialog } from "@mui/material";
import { ProdottoView } from "../models/Prodotto";
import ProdottoForm from "./ProdottoForm";

interface ProdottoFormDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  prodotto?: ProdottoView;
}

const ProdottoFormDialog: React.FC<ProdottoFormDialogProps> = ({
  open,
  onClose,
  onSuccess,
  prodotto,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <ProdottoForm
        isDialog={true}
        onClose={onClose}
        onSuccess={onSuccess}
        prodotto={prodotto}
      />
    </Dialog>
  );
};

export default ProdottoFormDialog;
