import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "'72', '72full', Arial, Helvetica, sans-serif",
    fontSize: 14,
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          background: "#0a6ed1", // SAP Fiori blue
          color: "#fff",
          fontWeight: "bold",
          fontSize: 14,
          lineHeight: 1.2,
        },
        body: {
          fontSize: 12,
          lineHeight: 1.2,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:nth-of-type(odd)": {
            backgroundColor: "rgba(10, 110, 209, 0.1)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
          marginBottom: "16px",
          "& .MuiInputLabel-root": {
            color: "#0a6ed1",
            minWidth: "120px",
          },
          "& .MuiInputBase-root": {
            flex: 1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          background: "#0a6ed1", // SAP Fiori blue
          color: "#fff",
          fontWeight: "bold",
          '&:hover': {
            background: "#084a9e", // Darker SAP Fiori blue
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#0a6ed1", // SAP Fiori blue
    },
    secondary: {
      main: "#5aaafa",
    },
    error: {
      main: "#e74c3c",
    },
    warning: {
      main: "#f39c12",
    },
    info: {
      main: "#3498db",
    },
    success: {
      main: "#2ecc71",
    },
    background: {
      default: "#f5f5f5",
    },
  },
});

export default theme;