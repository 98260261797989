import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Card,
  CardContent,
  CircularProgress,
} from "@mui/material";
import { ProdottoView } from "../models/Prodotto";
import { getProdottoById } from "../services/prodottiService";
import ProdottoForm from "./ProdottoForm";

const ProductDetail: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [prodotto, setProdotto] = useState<ProdottoView | null>(null);
  const [loading, setLoading] = useState(true);

  const hasFetchedProdotto = useRef(false);

  useEffect(() => {
    if (!hasFetchedProdotto.current) {
      fetchProdotto();
      hasFetchedProdotto.current = true;
    }
  }, [id]);

  const fetchProdotto = async () => {
    try {
      setLoading(true);
      if (id) {
        const prodottoData = await getProdottoById(parseInt(id));
        console.log("prodottoData", prodottoData);
        setProdotto(prodottoData);
      }
    } catch (error) {
      console.error("Error fetching prodotto:", error);
    } finally {
      setLoading(false);
    }
  };
  if (loading) {
    return <CircularProgress />;
  }

  if (!prodotto) {
    return <Typography variant="h6">Prodotto not found</Typography>;
  }

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <ProdottoForm
        onClose={() => navigate(-1)}
        onSuccess={fetchProdotto}
        prodotto={prodotto}
        isDialog={false}
      />
    </Box>
  );
};

export default ProductDetail;
