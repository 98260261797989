import axios from "axios";
import { ProdottoView, ProdottoCreate } from "../models/Prodotto";
import { API_URL } from "../constants";
import { ProdottoPage } from "../models/Page";

export const getProdottis = async (
  page: number,
  size: number,
  queryParams: string
): Promise<ProdottoPage> => {
  const response = await axios.get(
    API_URL + `/prodottis?page=${page}&size=${size}&${queryParams}`
  );
  return response.data;
};

export const createProdotto = async (
  prodotto: ProdottoCreate
): Promise<ProdottoView> => {
  const response = await axios.post(API_URL + "/prodottis", prodotto);
  return response.data;
};

export const getProdottoById = async (id: number): Promise<ProdottoView> => {
  const response = await axios.get(`${API_URL}/prodottis/${id}`);
  return response.data;
};

export const updateProdotto = async (
  id: number,
  prodotto: ProdottoView
): Promise<ProdottoView> => {
  const response = await axios.put(`${API_URL}/prodottis/${id}`, prodotto);
  return response.data;
};

export const deleteProdotto = async (id: number): Promise<void> => {
  await axios.delete(`${API_URL}/prodottis/${id}`);
};

export const extractPrices = async (id: number): Promise<void> => {
  await axios.get(`${API_URL}/prodottis/${id}/extractPrices`);
};

export const eseguiAbbinamentoRicerca = async (id: number): Promise<void> => {
  await axios.get(`${API_URL}/prodottis/${id}/eseguiAbbinamentoRicerca`);
};
export const eseguiInoltroPrezzi = async (id: number): Promise<void> => {
  await axios.get(`${API_URL}/prodottis/${id}/eseguiInoltroPrezzi`);
};
export const eseguiMatching = async (id: number): Promise<void> => {
  await axios.get(`${API_URL}/prodottis/${id}/eseguiMatching`);
};

export const extractAllPrices = async (): Promise<void> => {
  await axios.get(`${API_URL}/prodottis/extractPrices`);
};
export const eseguiAllInoltroPrezzi = async (): Promise<void> => {
  await axios.get(`${API_URL}/prodottis/eseguiInoltroPrezzi`);
};
export const eseguiAllMatching = async (): Promise<void> => {
  await axios.get(`${API_URL}/prodottis/eseguiMatching`);
};
export const uploadFile = async (file: File, fornitoreId: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fornitoreId", fornitoreId);

  try {
    const response = await axios.post(`${API_URL}/prodottis/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};
