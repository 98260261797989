import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Filters } from "../models/Page";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { it } from "date-fns/locale";
import { parse } from "date-fns";
import { CheckBox } from "@mui/icons-material";

interface ProductsFilterProps {
  queryParams: string;
  handleFilter: (queryParams: string) => void;
}
const ProductsFilter: React.FC<ProductsFilterProps> = ({
  handleFilter,
  queryParams,
}) => {
  const [filters, setFilters] = useState({
    dtDataInizio: null,
    dtDataFine: null,
    dataInizio: "",
    dataFine: "",
    dtAggiornamentoInizio: null,
    aggiornamentoInizio: "",
    dtAggiornamentoFine: null,
    aggiornamentoFine: "",
    codice: "",
    descrizione: "",
    prezzoMin: "",
    prezzoMax: "",
    disponibile: null,
    abilitaMatching: null,
    abilitaScraping: null,
    attivo: null,
  });
  React.useEffect(() => {
    const params = new URLSearchParams(queryParams);
    const newFilters: any = {};
    params.forEach((value, key) => {
      newFilters[key] = value;
    });
    newFilters.dtDataInizio =
      newFilters.dataInizio != null ? parseDate(newFilters.dataInizio) : null;
    newFilters.dtDataFine =
      newFilters.dataFine != null ? parseDate(newFilters.dataFine) : null;
    newFilters.dtAggiornamentoInizio =
      newFilters.aggiornamentoInizio != null
        ? parseDate(newFilters.aggiornamentoInizio)
        : null;
    newFilters.dtAggiornamentoFine =
      newFilters.aggiornamentoFine != null
        ? parseDate(newFilters.aggiornamentoFine)
        : null;
    setFilters(newFilters);
  }, [queryParams]);

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let updatedValue = value;
    if (e.target.type === "checkbox") {
      updatedValue = value === "on" ? "true" : "false";
    }
    setFilters({
      ...filters,
      [name]: updatedValue,
    });
  };
  const parseDate = (dateString: string): Date | null => {
    return parse(dateString, "dd-MM-yyyy", new Date());
  };

  const submitFilter = () => {
    handleFilter(buildQueryParams(filters));
  };

  const buildQueryParams = (filters: Filters): string => {
    const params = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (!key.startsWith("dt") && filters[key as keyof Filters]) {
        params.append(key, filters[key as keyof Filters]);
      }
    });
    return params.toString();
  };
  const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const handleDateChange = (name: string, date: Date | null) => {
    const dateFieldName = "dt" + name;
    setFilters({
      ...filters,
      [name]: date ? formatDate(date) : "",
      [dateFieldName]: date,
    });
  };

  const resetFilter = () => {
    handleFilter("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleFilter(buildQueryParams(filters));
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
      <Box p={2} width="300px">
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <FormControl fullWidth>
              <TextField
                label="Codice"
                name="codice"
                value={filters.codice}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    submitFilter();
                  }
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Descrizione"
                name="descrizione"
                value={filters.descrizione}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    submitFilter();
                  }
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Prezzo Minimo"
                type="number"
                name="prezzoMin"
                value={filters.prezzoMin}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    submitFilter();
                  }
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  },
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <TextField
                label="Prezzo Massimo"
                type="number"
                name="prezzoMax"
                value={filters.prezzoMax}
                onChange={handleFilterChange}
                fullWidth
                margin="normal"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    submitFilter();
                  }
                }}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">€</InputAdornment>
                    ),
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="disponibile"
                    onChange={handleFilterChange}
                    checked={filters.disponibile? filters.disponibile === "true" : false}
                  />
                }
                label="Disponibile"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="attivo"
                    onChange={handleFilterChange}
                    checked={filters.attivo? filters.attivo === "true" : false}
                  />
                }
                label="Attivo"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="abilitaMatching"
                    onChange={handleFilterChange}
                    checked={filters.abilitaMatching? filters.abilitaMatching === "true" : false}
                  />
                }
                label="Matching abilitato"
              />
            </FormControl>
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Checkbox
                    name="abilitaScraping"
                    onChange={handleFilterChange}
                    checked={filters.abilitaScraping? filters.abilitaScraping === "true" : false}
                  />
                }
                label="Scraping abilitato"
              />
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                label="Aggiornamento Inizio"
                name="aggiornamentoInizio"
                sx={{
                  marginBottom: "8px",
                }}
                value={
                  filters.dtAggiornamentoInizio
                    ? filters.dtAggiornamentoInizio
                    : null
                }
                onChange={(date: Date | null) =>
                  handleDateChange("aggiornamentoInizio", date)
                }
              />
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                label="Aggiornamento Fine"
                name="aggiornamentoFine"
                sx={{
                  marginBottom: "8px",
                }}
                value={
                  filters.dtAggiornamentoFine
                    ? filters.dtAggiornamentoFine
                    : null
                }
                onChange={(date: Date | null) =>
                  handleDateChange("aggiornamentoFine", date)
                }
              />
              <FormControl fullWidth>
                <DatePicker
                  sx={{
                    marginTop: "16px",
                    marginBottom: "8px",
                  }}
                  label="Inserito Inzio"
                  name="dataInizio"
                  value={filters.dtDataInizio ? filters.dtDataInizio : null}
                  onChange={(date: Date | null) =>
                    handleDateChange("dataInizio", date)
                  }
                />
              </FormControl>
              <FormControl fullWidth>
                <DatePicker
                  sx={{
                    marginBottom: "8px",
                  }}
                  label="Inserito Fine"
                  name="dataFine"
                  value={filters.dtDataFine ? filters.dtDataFine : null}
                  onChange={(date: Date | null) =>
                    handleDateChange("dataFine", date)
                  }
                />
              </FormControl>
            </FormControl>

            <Button onClick={submitFilter} color="primary" variant="contained">
              Applica Filtri
            </Button>
            <Button onClick={resetFilter} color="secondary" variant="contained">
              Reset
            </Button>
          </FormGroup>
        </form>
      </Box>
    </LocalizationProvider>
  );
};
export default ProductsFilter;
