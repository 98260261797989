import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Card,
  CardContent,
  IconButton,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { getFornitoris } from "../services/fornitoriService";
import FornitoreForm from "./FornitoreForm";
import { Fornitore } from "../models/Fornitore";
import { Edit, Wifi2BarOutlined } from "@mui/icons-material";

const Fornitori: React.FC = () => {
  const [fornitori, setFornitori] = useState<Fornitore[]>([]);
  const [loading, setLoading] = useState(true);
  const [openFornitoreForm, setOpenFornitoreForm] = useState(false);
  const [selectedFornitore, setSelectedFornitore] = useState<Fornitore | null>(
    null
  );

  const fetchData = async () => {
    setLoading(true);
    try {
      const fornitoriData = await getFornitoris();
      setFornitori(fornitoriData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleOpenFornitoreForm = (fornitore?: Fornitore) => {
    setSelectedFornitore(fornitore || null);
    setOpenFornitoreForm(true);
  };

  const handleCloseFornitoreForm = () => {
    setOpenFornitoreForm(false);
  };

  const handleSuccessFornitore = async () => {
    fetchData();
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography component="h2" variant="h6">
          Fornitori
        </Typography>
        <Box>
          <IconButton onClick={() => handleOpenFornitoreForm()} color="primary">
            <AddIcon />
          </IconButton>
          <IconButton onClick={() => fetchData()} color="primary">
            <RefreshIcon />
          </IconButton>
        </Box>
      </Box>
      <Card sx={{ mb: 2, borderRadius: 2, border: "1px solid #40a7f2" }}>
        <CardContent>
          <TableContainer component={Paper} sx={{ maxHeight: "70vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Codice</TableCell>
                  <TableCell>Descrizione</TableCell>
                  <TableCell>Website</TableCell>
                  <TableCell>Competitor</TableCell>
                  <TableCell>HTML</TableCell>
                  <TableCell>HTML Sconto</TableCell>
                  <TableCell>HTML Disp</TableCell>
                  <TableCell>Formato CSV</TableCell>
                  <TableCell>Separatore CSV</TableCell>
                  <TableCell>Token Sessione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow sx={{ minHeight: "74px" }}>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                      <Typography>Loading...</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  fornitori.map((fornitore) => (
                    <TableRow key={fornitore.id}>
                      <TableCell>{fornitore.id}</TableCell>

                      <TableCell>
                        <IconButton
                          onClick={() => handleOpenFornitoreForm(fornitore)}
                          color="primary"
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        {fornitore.codice}{" "}
                        {fornitore.matchingAttivo ? (
                          <Wifi2BarOutlined
                            sx={{ color: "green" }}
                            titleAccess="Matching Attivo"
                          />
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell>{fornitore.descrizione}</TableCell>
                      <TableCell>{fornitore.website}</TableCell>
                      <TableCell>
                        {fornitore.competitor ? "SI" : "NO"}
                      </TableCell>
                      <TableCell>{fornitore.selectorHtml}</TableCell>
                      <TableCell>{fornitore.selectorHtmlSconto}</TableCell>
                      <TableCell>
                        {fornitore.selectorHtmlDisponibilita}
                      </TableCell>
                      <TableCell>{fornitore.formatoCsv}</TableCell>
                      <TableCell>{fornitore.separatoreCsv}</TableCell>
                      <TableCell sx={{ maxWidth: "50px" }}>
                        {fornitore.tokenSessione ? "SI" : "NO"}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <FornitoreForm
        open={openFornitoreForm}
        onClose={handleCloseFornitoreForm}
        onSuccess={handleSuccessFornitore}
        fornitore={selectedFornitore}
      />
    </Box>
  );
};

export default Fornitori;
