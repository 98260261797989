import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
  Card,
  CardContent,
  CardActions,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import {
  createProdotto,
  eseguiAbbinamentoRicerca,
  eseguiInoltroPrezzi,
  eseguiMatching,
  extractPrices,
  updateProdotto,
} from "../services/prodottiService";
import { getFornitoris } from "../services/fornitoriService";
import {
  createProdottoFornitorePrezzo,
  deleteProdottoFornitorePrezzo,
  updateProdottoFornitorePrezzo,
} from "../services/prodottiFornitoriPrezziService";
import { Fornitore } from "../models/Fornitore";
import { ProdottiRicercaRestModel, ProdottoView } from "../models/Prodotto";
import { ProdottoFornitorePrezzo } from "../models/ProdottoFornitorePrezzo";
import {
  AddCircleOutline,
  AddCircleOutlined,
  AddRounded,
  Cancel,
  Check,
  CurrencyExchange,
  Delete,
  Edit,
  EditNotifications,
  Euro,
  RefreshRounded,
  Save,
  ScatterPlot,
  SelectAllOutlined,
  Wifi2BarOutlined,
  X,
} from "@mui/icons-material";
import { IncomingMessage } from "http";

interface ProdottoFormProps {
  onClose: () => void;
  onSuccess: () => void;
  isDialog: boolean;
  prodotto?: ProdottoView;
}

const ProdottoForm: React.FC<ProdottoFormProps> = ({
  onClose,
  onSuccess,
  isDialog,
  prodotto,
}) => {
  const [fornitori, setFornitori] = useState<Fornitore[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [matchingData, setMatchingData] = useState<ProdottiRicercaRestModel[]>(
    prodotto?.matching || []
  );

  const [codice, setCodice] = useState(prodotto?.codice || "");
  const [descrizione, setDescrizione] = useState(prodotto?.descrizione || "");
  const [prezzo, setPrezzo] = useState(prodotto?.prezzo?.toString() || "");
  const [attivo, setAttivo] = useState(prodotto?.attivo || false);
  const [disponibile, setDisponibile] = useState(
    prodotto?.disponibile || false
  );
  const [abilitaMatching, setAbilitaMatching] = useState(
    prodotto?.abilitaMatching || false
  );
  const [abilitaScraping, setAbilitaScraping] = useState(
    prodotto?.abilitaScraping || false
  );
  const [prezziFornitori, setPrezziFornitori] = useState<
    ProdottoFornitorePrezzo[]
  >(prodotto?.prezziFornitori || []);
  const [editing, setEditing] = useState<{ [key: number]: boolean }>({});
  const [addedPrezziFornitori, setAddedPrezziFornitori] = useState<
    ProdottoFornitorePrezzo[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const eseguiScrapingHandler = async () => {
    if (prodotto !== undefined && prodotto.id !== undefined) {
      setLoading(true);
      try {
        await extractPrices(prodotto.id);
        setSuccessMessage("Scraping eseguito con successo!");

        onSuccess();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  const eseguiAbbinamentoRicercaHandler = async (id: number) => {
    setLoading(true);
    try {
      await eseguiAbbinamentoRicerca(id);
      setSuccessMessage("Abbinamento matching eseguito con successo!");

      onSuccess();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const eseguiMatchingHandler = async () => {
    if (prodotto !== undefined && prodotto.id !== undefined) {
      setLoading(true);
      try {
        await eseguiMatching(prodotto.id);
        setSuccessMessage("Matching eseguito con successo!");

        onSuccess();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  const inoltraPrezziHandler = async () => {
    if (prodotto !== undefined && prodotto.id !== undefined) {
      setLoading(true);
      try {
        await eseguiInoltroPrezzi(prodotto.id);
        setSuccessMessage("Prezzi inoltrati con successo!");

        onSuccess();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };
  // const [fornitoreDetails, setFornitoreDetails] = useState<{
  //   [key: number]: { urlPagina: string; selectorHtml: string };
  // }>({});
  // const [extracting, setExtracting] = useState(false);
  // const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const hasFetchedFornitori = useRef(false);

  useEffect(() => {
    if (!hasFetchedFornitori.current) {
      resetForm();
      fetchFornitori();
      hasFetchedFornitori.current = true;
    }
  }, []);

  const fetchFornitori = async () => {
    const fornitoriData = await getFornitoris();
    setFornitori(fornitoriData);
  };

  const resetForm = () => {
    setCodice(prodotto?.codice || "");
    setDescrizione(prodotto?.descrizione || "");
    setPrezzo(prodotto?.prezzo?.toString() || "");
    setDisponibile(prodotto?.disponibile || false);
    setAttivo(prodotto?.attivo || false);
    setAbilitaMatching(prodotto?.abilitaMatching || true);
    setAbilitaScraping(prodotto?.abilitaScraping || true);
    setMatchingData(prodotto?.matching || []);
  };

  const handleSubmit = async () => {
    try {
      const insertedProdotto = prodotto
        ? await updateProdotto(prodotto.id, {
            ...prodotto,
            codice,
            descrizione,
            attivo: attivo,
            prezzo: parseFloat(prezzo),
          })
        : await createProdotto({
            codice,
            descrizione,
            attivo: attivo,
            prezzo: parseFloat(prezzo),
          });

      await Promise.all(
        addedPrezziFornitori.map((pf) =>
          createProdottoFornitorePrezzo({
            ...pf,
            prodottoId: insertedProdotto.id,
          })
        )
      );

      onSuccess();
    } catch (error) {
      console.error("Error creating prodotto:", error);
    }
  };

  const handleEdit = (prezziFornitori: ProdottoFornitorePrezzo) => {
    setEditing((prev) => ({ ...prev, [prezziFornitori.id ?? -1]: true }));
  };

  const handleSave = async (prezziFornitori: ProdottoFornitorePrezzo) => {
    await updateProdottoFornitorePrezzo(
      prezziFornitori.id ?? -1,
      prezziFornitori
    );
    setEditing((prev) => ({ ...prev, [prezziFornitori.id ?? -1]: false }));
    // try {
    //   const updatedPrezzo = editedPrezzi[id];
    //   await updateProdottoFornitorePrezzo(id, updatedPrezzo);
    //   setEditing((prev) => ({ ...prev, [id]: false }));
    //   onSuccess();
    // } catch (error) {
    //   console.error('Error updating prezzo:', error);
    // }
  };
  const handleSaveNew = async (
    prezziFornitori: ProdottoFornitorePrezzo,
    index: number
  ) => {
    // try {
    //   const updatedPrezzo = editedPrezzi[id];
    //   await updateProdottoFornitorePrezzo(id, updatedPrezzo);
    //   setEditing((prev) => ({ ...prev, [id]: false }));
    //   onSuccess();
    // } catch (error) {
    //   console.error('Error updating prezzo:', error);
    // }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteProdottoFornitorePrezzo(id);
      onSuccess();
    } catch (error) {
      console.error("Error deleting prezzo:", error);
    }
  };

  const handleDeleteNew = async (
    prezziFornitoriObject: ProdottoFornitorePrezzo,
    index: number
  ) => {
    const updatedPrezziFornitori = [...addedPrezziFornitori];
    updatedPrezziFornitori.splice(index, 1);
    setAddedPrezziFornitori(updatedPrezziFornitori);
  };
  const handleChange = (
    object: ProdottoFornitorePrezzo,
    field: keyof ProdottoFornitorePrezzo,
    value: string | number
  ) => {
    const updatedPrezziFornitori = [...prezziFornitori];
    const index = updatedPrezziFornitori.findIndex((pf) => pf.id === object.id);
    updatedPrezziFornitori[index] = {
      ...object,
      [field]: value,
    };
    setPrezziFornitori(updatedPrezziFornitori);
  };
  const handleChangeNew = (
    object: ProdottoFornitorePrezzo,
    index: number,
    field: keyof ProdottoFornitorePrezzo,
    value: string | number
  ) => {
    const updatedPrezziFornitori = [...addedPrezziFornitori];
    updatedPrezziFornitori[index] = {
      ...object,
      [field]: value,
    };
    setAddedPrezziFornitori(updatedPrezziFornitori);
  };

  const handleAddPrezzoFornitore = () => {
    setAddedPrezziFornitori((prev) => [
      ...prev,
      {
        prezzo: 0,
        urlPagina: "",
        selectorHtml: "",
        dataIns: "",
        cProdottoFornitore: "",
        esitoEstrazione: "",
        fornitoreId: 0,
        prodottoId: prodotto?.id || 0,
        fornitore: fornitori[0],
        prodotto: prodotto,
        disponibile: false,
      },
    ]);
  };
  const handleTabChange = (event: React.SyntheticEvent, newValue: any) => {
    setTabIndex(newValue);
    const section = document.getElementById(`section-${newValue}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleCloseSnackbar = () => {
    setSuccessMessage("");
  };

  const renderFornitoreName = (id: number) => {
    const forntioreNameToRender = fornitori.find(
      (fornitore) => fornitore.id === id
    );
    if (forntioreNameToRender) {
      return forntioreNameToRender.codice;
    }
  };
  // Group matching data by fornitore
  const groupedData = matchingData.reduce((acc, item) => {
    if (!acc[item.idFornitore]) {
      acc[item.idFornitore] = [];
    }
    acc[item.idFornitore].push(item);
    return acc;
  }, {} as Record<number, ProdottiRicercaRestModel[]>);

  const getCodiceFornitore = (id: number) => {
    const forntioreNameToRender = fornitori.find(
      (fornitore) => fornitore.id === id
    );
    if (forntioreNameToRender) {
      return forntioreNameToRender.codice;
    }
  };

  const createWebsite = (idFornitore: number, codiceProdotto: string) => {
    let url = fornitori.find(
      (fornitore) => fornitore.id === idFornitore
    )?.website;
    url = url?.replace("{codiceProdotto}", codiceProdotto);
    return url;
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: "#fff",
          boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        }}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          sx={{ borderBottom: 1, borderColor: "divider" }}
        >
          <Tab label="Anagrafica Centrale" />
          <Tab label="Prezzi Fornitori/Competitor" />
          <Tab label="Matching" />
          <Tab label="Storico" />
        </Tabs>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Card id="section-0">
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  {prodotto
                    ? prodotto.codice + " " + prodotto.descrizione
                    : "Add New Prodotto"}
                </Box>
                <Box sx={{ display: "flex" }}>
                  <IconButton
                    title="Esegui Scraping"
                    onClick={eseguiScrapingHandler}
                    color="primary"
                    disabled={loading}
                  >
                    <ScatterPlot />
                  </IconButton>
                  <IconButton
                    title="Esegui Matching"
                    onClick={eseguiMatchingHandler}
                    color="primary"
                    disabled={loading}
                  >
                    <Wifi2BarOutlined />
                  </IconButton>
                  <IconButton
                    title="Inoltra prezzi"
                    onClick={inoltraPrezziHandler}
                    color="primary"
                    disabled={loading}
                  >
                    <CurrencyExchange />
                  </IconButton>
                  <IconButton
                    onClick={onSuccess}
                    title="Refresh"
                    color="primary"
                  >
                    <RefreshRounded />
                  </IconButton>
                  <IconButton onClick={onClose} title="Annulla" color="primary">
                    <Cancel />
                  </IconButton>
                </Box>
              </Box>
            </Typography>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <TextField
                label="Codice"
                value={codice}
                onChange={(e) => setCodice(e.target.value)}
                required
              />
              <TextField
                label="Descrizione"
                value={descrizione}
                onChange={(e) => setDescrizione(e.target.value)}
                required
              />
              <TextField
                label="Prezzo"
                type="number"
                value={prezzo}
                onChange={(e) => setPrezzo(e.target.value)}
                required
              />
              <Box sx={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={disponibile}
                      onChange={(e) => setDisponibile(e.target.checked)}
                    />
                  }
                  label={
                    disponibile
                      ? "Disponibile da " +
                        prodotto?.numeroFornitoriDisponibile +
                        " fornitori"
                      : "Non disponibile"
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={abilitaMatching}
                      onChange={(e) => setAbilitaMatching(e.target.checked)}
                    />
                  }
                  label={
                    abilitaMatching
                      ? "Matching abilitato"
                      : "Matching non abilitato"
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={abilitaScraping}
                      onChange={(e) => setAbilitaScraping(e.target.checked)}
                    />
                  }
                  label={
                    abilitaScraping
                      ? "Scraping abilitato"
                      : "Scraping non abilitato"
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      checked={attivo}
                      onChange={(e) => setAttivo(e.target.checked)}
                    />
                  }
                  label={
                    attivo
                      ? "Attivo inoltra prezzo"
                      : "Non attivo inoltra prezzo"
                  }
                />
              </Box>
              {prodotto?.id ? (
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  Fornitori coinvolti: {prodotto?.prezziFornitori?.length}{" "}
                  {prodotto?.prezziFornitori
                    .map((pf) => pf.fornitore?.descrizione)
                    .join(", ")}
                </Box>
              ) : null}
            </Box>
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Salva
            </Button>
          </CardActions>
        </Card>
        <Card id="section-1" sx={{ mt: 2 }}>
          <CardContent>
            {prodotto?.id && prezziFornitori && (
              <Box sx={{ mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">Prezzi Fornitori</Typography>
                  <Box>
                    <IconButton
                      title="Aggiungi Fornitore"
                      onClick={handleAddPrezzoFornitore}
                      color="primary"
                    >
                      <AddCircleOutlined />
                    </IconButton>
                  </Box>
                </Box>
                <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell>Actions</TableCell>
                        <TableCell>Prodotto</TableCell>
                        <TableCell>Disponibile</TableCell>
                        <TableCell>Fornitore</TableCell>
                        <TableCell>Prezzo</TableCell>
                        <TableCell>Prezzo Sconto</TableCell>
                        <TableCell>URL Pagina</TableCell>
                        <TableCell>Selector HTML</TableCell>
                        <TableCell>cProdottoFornitore</TableCell>
                        <TableCell>Data Ins</TableCell>
                        <TableCell>esitoEstrazione</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {prezziFornitori
                        .sort((a, b) => {
                          if (a.disponibile === b.disponibile) {
                            return (a.prezzo ?? 0) - (b.prezzo ?? 0);
                          } 
                          return a.disponibile ? -1 : 1;
                        })
                        .map((pf) => (
                          <TableRow key={pf.id}>
                            <TableCell>{pf.id}</TableCell>
                            <TableCell>
                              <Box sx={{ display: "flex", gap: 1 }}>
                                {editing[pf.id ?? -1] ? (
                                  <IconButton
                                    onClick={() => handleSave(pf)}
                                    color="primary"
                                  >
                                    <Save />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    onClick={() => handleEdit(pf)}
                                    color="primary"
                                  >
                                    <Edit />
                                  </IconButton>
                                )}

                                {pf.id ? (
                                  <IconButton
                                    onClick={() => handleDelete(pf.id ?? -1)}
                                    color="primary"
                                  >
                                    <Delete />
                                  </IconButton>
                                ) : null}
                              </Box>
                            </TableCell>
                            <TableCell>{pf.prodotto?.descrizione}</TableCell>
                            <TableCell>
                              {pf.disponibile ? (
                                <Check sx={{ color: "green" }} />
                              ) : (
                                <X sx={{ color: "red" }} />
                              )}
                            </TableCell>

                            <TableCell>
                              {editing[pf.id ?? -1] ? (
                                <FormControl fullWidth>
                                  <InputLabel>Fornitore</InputLabel>
                                  <Select
                                    value={pf?.fornitoreId || ""}
                                    onChange={(e) =>
                                      handleChange(
                                        pf,
                                        "fornitoreId",
                                        parseInt(e.target.value as string)
                                      )
                                    }
                                    input={<OutlinedInput label="Fornitore" />}
                                  >
                                    {fornitori.map((fornitore) => (
                                      <MenuItem
                                        key={fornitore.id ?? -1}
                                        value={fornitore.id ?? -1}
                                      >
                                        {fornitore.descrizione}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                pf.fornitore?.descrizione
                              )}
                            </TableCell>
                            <TableCell>
                              {editing[pf.id ?? -1] ? (
                                <TextField
                                  value={pf?.prezzo || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      pf,
                                      "prezzo",
                                      parseFloat(e.target.value)
                                    )
                                  }
                                  fullWidth
                                />
                              ) : (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  <Euro
                                    sx={{ verticalAlign: "middle", mr: 1 }}
                                  />
                                  {pf.prezzo}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell>
                              {editing[pf.id ?? -1] ? (
                                <TextField
                                  value={pf?.prezzoSconto || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      pf,
                                      "prezzoSconto",
                                      parseFloat(e.target.value)
                                    )
                                  }
                                  fullWidth
                                />
                              ) : (
                                <Box sx={{ display: "flex", gap: 1 }}>
                                  <Euro
                                    sx={{ verticalAlign: "middle", mr: 1 }}
                                  />
                                  {pf.prezzoSconto}
                                </Box>
                              )}
                            </TableCell>
                            <TableCell>
                              {editing[pf.id ?? -1] ? (
                                <TextField
                                  value={pf?.urlPagina || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      pf,
                                      "urlPagina",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              ) : (
                                pf.urlPagina
                              )}
                            </TableCell>
                            <TableCell>
                              {editing[pf.id ?? -1] ? (
                                <TextField
                                  value={pf?.selectorHtml || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      pf,
                                      "selectorHtml",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              ) : (
                                pf.selectorHtml
                              )}
                            </TableCell>
                            <TableCell>
                              {" "}
                              {editing[pf.id ?? -1] ? (
                                <TextField
                                  value={pf?.cProdottoFornitore || ""}
                                  onChange={(e) =>
                                    handleChange(
                                      pf,
                                      "cProdottoFornitore",
                                      e.target.value
                                    )
                                  }
                                  fullWidth
                                />
                              ) : (
                                <a
                                  href={createWebsite(
                                    pf.fornitoreId,
                                    pf.cProdottoFornitore
                                      ? pf.cProdottoFornitore
                                      : pf.prodotto?.codice || ""
                                  )}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {pf.cProdottoFornitore}
                                </a>
                              )}
                            </TableCell>
                            <TableCell>{pf.dataIns}</TableCell>
                            <TableCell title={pf.esitoEstrazione}>
                              {pf.esitoEstrazione?.length ?? 0 > 50
                                ? pf.esitoEstrazione?.substring(0, 50)
                                : pf.esitoEstrazione}
                            </TableCell>
                          </TableRow>
                        ))}
                      {addedPrezziFornitori.map((pf, index) => (
                        <TableRow key={index}>
                          <TableCell></TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() => handleDeleteNew(pf, index)}
                              color="primary"
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                          <TableCell>{pf.prodotto?.descrizione}</TableCell>
                          <TableCell>
                            <FormControl fullWidth>
                              <InputLabel>Fornitore</InputLabel>
                              <Select
                                value={pf?.fornitoreId || ""}
                                onChange={(e) =>
                                  handleChangeNew(
                                    pf,
                                    index,
                                    "fornitoreId",
                                    parseInt(e.target.value as string)
                                  )
                                }
                                input={<OutlinedInput label="Fornitore" />}
                              >
                                {fornitori.map((fornitore) => (
                                  <MenuItem
                                    key={fornitore.id ?? -1}
                                    value={fornitore.id ?? -1}
                                  >
                                    {fornitore.descrizione}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={pf?.prezzo || ""}
                              onChange={(e) =>
                                handleChangeNew(
                                  pf,
                                  index,
                                  "prezzo",
                                  parseFloat(e.target.value)
                                )
                              }
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={pf?.urlPagina || ""}
                              onChange={(e) =>
                                handleChangeNew(
                                  pf,
                                  index,
                                  "urlPagina",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={pf?.selectorHtml || ""}
                              onChange={(e) =>
                                handleChangeNew(
                                  pf,
                                  index,
                                  "selectorHtml",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              value={pf?.cProdottoFornitore || ""}
                              onChange={(e) =>
                                handleChangeNew(
                                  pf,
                                  index,
                                  "cProdottoFornitore",
                                  e.target.value
                                )
                              }
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>{pf.dataIns}</TableCell>
                          <TableCell>{pf.esitoEstrazione}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
          </CardContent>
          <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Salva
            </Button>
          </CardActions>
        </Card>
        <Card id="section-2" sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Matching
            </Typography>
            {Object.keys(groupedData).map((fornitoreId) => (
              <Box key={fornitoreId} sx={{ mb: 4 }}>
                <Typography variant="h6" component="div" gutterBottom>
                  Fornitore: {getCodiceFornitore(parseInt(fornitoreId))}
                </Typography>
                <TableContainer component={Paper} sx={{ maxHeight: "500px" }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Azioni</TableCell>
                        <TableCell>Codice</TableCell>
                        <TableCell>Descrizione</TableCell>
                        <TableCell>Fornitore</TableCell>
                        <TableCell>Prezzo</TableCell>
                        <TableCell>Disponibilità</TableCell>
                        <TableCell>Esito Matching</TableCell>
                        <TableCell>Data Inserimento</TableCell>
                        <TableCell>Data Aggiornamento</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupedData[parseInt(fornitoreId)].map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              {!item.selezionato ? (
                                <IconButton
                                  title="Esegui Abbinamento Matching"
                                  onClick={() =>
                                    eseguiAbbinamentoRicercaHandler(item.id)
                                  }
                                  color="primary"
                                  disabled={loading}
                                >
                                  <SelectAllOutlined />
                                </IconButton>
                              ) : (
                                ""
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <a
                              href={createWebsite(
                                item.idFornitore,
                                item.codice
                              )}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {item.codice}
                            </a>
                          </TableCell>
                          <TableCell>{item.descrizione}</TableCell>
                          <TableCell>
                            {renderFornitoreName(item.idFornitore)}
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <Euro sx={{ verticalAlign: "middle", mr: 1 }} />
                              {item.prezzo}
                            </Box>
                          </TableCell>
                          <TableCell>
                            {item.disponibile ? (
                              <Check sx={{ color: "green" }} />
                            ) : (
                              ""
                            )}
                            {item.strDisponibilita}
                          </TableCell>
                          <TableCell>{item.esitoMatching}</TableCell>
                          <TableCell>
                            {new Date(item.dataIns).toLocaleDateString()}
                          </TableCell>
                          <TableCell>
                            {new Date(item.dataUpd).toLocaleDateString()}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </CardContent>
        </Card>
        <Card id="section-3" sx={{ mt: 2 }}>
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Storico
            </Typography>
            {/* Add your content here */}
          </CardContent>
        </Card>
      </Box>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProdottoForm;
