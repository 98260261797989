import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { createFornitore, updateFornitore } from "../services/fornitoriService";
import { Fornitore } from "../models/Fornitore";

interface FornitoreFormProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
  fornitore: Fornitore | null;
}

const FornitoreForm: React.FC<FornitoreFormProps> = ({
  open,
  onClose,
  onSuccess,
  fornitore,
}) => {
  const [codice, setCodice] = useState(fornitore?.codice || "");
  const [descrizione, setDescrizione] = useState(fornitore?.descrizione || "");
  const [website, setWebsite] = useState(fornitore?.website || "");
  const [formatoCsv, setFormatoCsv] = useState(fornitore?.formatoCsv || "");
  const [tokenSessione, setTokenSessione] = useState(
    fornitore?.tokenSessione || ""
  );
  const [competitor, setCompetitor] = useState(fornitore?.competitor || false);
  const [matchingAttivo, setMatchingAttivo] = useState(
    fornitore?.competitor || false
  );
  const [selectorHtml, setSelectorHtml] = useState(
    fornitore?.selectorHtml || ""
  );
  const [selectorHtmlSconto, setSelectorHtmlSconto] = useState(
    fornitore?.selectorHtmlSconto || ""
  );
  const [selectorHtmlDisponibilita, setSelectorHtmlDisponibilita] = useState(
    fornitore?.selectorHtmlDisponibilita || ""
  );
  const [separatoreCsv, setSeparatoreCsv] = useState(
    fornitore?.separatoreCsv || ""
  );

  useEffect(() => {
    if (open) {
      setCodice(fornitore?.codice || "");
      setDescrizione(fornitore?.descrizione || "");
      setWebsite(fornitore?.website || "");
      setTokenSessione(fornitore?.tokenSessione || "");
      setCompetitor(fornitore?.competitor || false);
      setMatchingAttivo(fornitore?.matchingAttivo || false);
      setSelectorHtml(fornitore?.selectorHtml || "");
      setSelectorHtmlSconto(fornitore?.selectorHtmlSconto || "");
      setSelectorHtmlSconto(fornitore?.selectorHtmlDisponibilita || "");
      setSeparatoreCsv(fornitore?.separatoreCsv || "");
      setFormatoCsv(fornitore?.formatoCsv || "");
    }
  }, [open, fornitore]);

  const handleSubmit = async () => {
    try {
      if (fornitore && fornitore.id !== null) {
        await updateFornitore(fornitore.id, {
          codice,
          descrizione,
          website,
          tokenSessione,
          id: fornitore.id,
          dataIns: fornitore.dataIns,
          competitor,
          matchingAttivo,
          selectorHtml,
          selectorHtmlSconto,
          selectorHtmlDisponibilita,
          formatoCsv,
          separatoreCsv,
        });
      } else {
        await createFornitore({
          codice,
          descrizione,
          website,
          tokenSessione,
          id: null,
          dataIns: null,
          competitor,
          matchingAttivo,
          selectorHtml,
          selectorHtmlSconto,
          selectorHtmlDisponibilita,
          formatoCsv,
          separatoreCsv,
        });
      }
      onSuccess();
      onClose();
    } catch (error) {
      console.error("Error saving fornitore:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {fornitore ? "Edit Fornitore" : "Add New Fornitore"}
      </DialogTitle>
      <DialogContent>
        {" "}
        <Box
          component="form"
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 4,
          }}
        >
          <Box
            sx={{
              width: "80vw",
            }}
          >
            <TextField
              label="Codice"
              value={codice}
              onChange={(e) => setCodice(e.target.value)}
              required
            />
            <TextField
              label="Descrizione"
              value={descrizione}
              onChange={(e) => setDescrizione(e.target.value)}
              required
            />
            <TextField
              label="Website"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              required
            />
            <TextField
              label="Token Sessione"
              value={tokenSessione}
              onChange={(e) => setTokenSessione(e.target.value)}
              required
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={competitor}
                  onChange={(e) => setCompetitor(e.target.checked)}
                />
              }
              label="Competitor"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={matchingAttivo}
                  onChange={(e) => setMatchingAttivo(e.target.checked)}
                />
              }
              label="Matching"
            />
            <TextField
              label="Selector HTML"
              value={selectorHtml}
              onChange={(e) => setSelectorHtml(e.target.value)}
              required
            />
            <TextField
              label="Selector HTML Sconto"
              value={selectorHtmlSconto}
              onChange={(e) => setSelectorHtmlSconto(e.target.value)}
              required
            />
            <TextField
              label="Selector HTML Disp"
              value={selectorHtmlDisponibilita}
              onChange={(e) => setSelectorHtmlDisponibilita(e.target.value)}
              required
            />
            <TextField
              label="Formato CSV"
              value={formatoCsv}
              onChange={(e) => setFormatoCsv(e.target.value)}
              required
            />
            <TextField
              label="Separatore CSV"
              value={separatoreCsv}
              onChange={(e) => setSeparatoreCsv(e.target.value)}
              required
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FornitoreForm;
