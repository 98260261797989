import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Snackbar,
  Alert,
  IconButton,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  DialogActions,
  DialogTitle,
  Drawer,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { ProdottoView } from "../models/Prodotto";
import {
  getProdottis,
  extractPrices,
  extractAllPrices,
  deleteProdotto,
  uploadFile,
  eseguiMatching,
  eseguiAllMatching,
  eseguiAllInoltroPrezzi,
} from "../services/prodottiService";
import ProdottoFormDialog from "./ProdottoFormDialog";
import {
  AccountBalanceWallet,
  Check,
  CurrencyExchange,
  Delete,
  DeliveryDining,
  Edit,
  Euro,
  MilitaryTechOutlined,
  MoneyOffCsredTwoTone,
  MoneyTwoTone,
  ScatterPlot,
  Search,
  SearchTwoTone,
  Settings,
  UploadFile,
  Wifi1Bar,
  Wifi1BarRounded,
  Wifi2BarOutlined,
  WifiChannelSharp,
} from "@mui/icons-material";
import { getFornitoris } from "../services/fornitoriService";
import { Fornitore } from "../models/Fornitore";
import ProductsFilter from "./ProductsFilter";

const Products: React.FC = () => {
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [queryParams, setQueryParams] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [prodotti, setProdotti] = useState<ProdottoView[]>([]); //
  const [totalRows, setTotalRows] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [selectedFornitore, setSelectedFornitore] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fornitori, setFornitori] = useState<Fornitore[]>([]); // Assume this is populated with the list of fornitori
  const [openProdottoForm, setOpenProdottoForm] = useState(false);
  const [selectedProdotto, setSelectedProdotto] = useState<ProdottoView | null>(
    null
  );
  const [extracting, setExtracting] = useState<{ [key: number]: boolean }>({});
  const [extractingAll, setExtractingAll] = useState(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");

  useEffect(() => {
    fetchData(
      queryParams +
        (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
    );
  }, [page, rowsPerPage, orderBy, orderDirection]);

  useEffect(() => {
    const fetchFornitori = async () => {
      try {
        const data = await getFornitoris();
        setFornitori(data);
      } catch (error) {
        console.error("Errore nel recupero dei dati:", error);
      }
    };
    fetchFornitori();
  }, []);

  const fetchData = async (queryParams: string) => {
    setLoading(true);
    try {
      const data = await getProdottis(page, rowsPerPage, queryParams);
      setProdotti(data.content);
      setTotalRows(data.page.totalElements);
    } catch (error) {
      console.error("Errore nel recupero dei dati:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (newQueryParams: string) => {
    // Implementa la logica di filtro qui
    setQueryParams(newQueryParams);
    setIsFilterDrawerOpen(false);
    fetchData(
      newQueryParams +
        (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
    );
  };
  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleFornitoreChange = (value: string) => {
    setSelectedFornitore(value);
  };

  const handleUpload = async () => {
    try {
      if (file) {
        const response = await uploadFile(file, selectedFornitore);
        console.log(response);
        handleCloseUploadModal();
        fetchData(
          queryParams +
            (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
        );
      } else {
        console.error("No file selected");
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const handleOpenProdottoForm = (prodotto?: ProdottoView) => {
    setSelectedProdotto(prodotto || null);
    setOpenProdottoForm(true);
  };

  const handleCloseProdottoForm = () => {
    setOpenProdottoForm(false);
  };

  const handleSuccessProdotto = async () => {
    fetchData(
      queryParams +
        (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
    );
  };

  const handleExtractPrices = async (id: number) => {
    setExtracting((prev) => ({ ...prev, [id]: true }));
    try {
      await extractPrices(id);
      setSuccessMessage("Prices extracted successfully");
    } catch (error) {
      console.error("Error extracting prices:", error);
    } finally {
      setExtracting((prev) => ({ ...prev, [id]: false }));
    }
  };

  const handleDelete = async (id: number) => {
    try {
      await deleteProdotto(id);
      setSuccessMessage("Prodotto " + id + " deleted successfully");
      fetchData(
        queryParams +
          (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
      );
    } catch (error) {
      console.error("Error extracting prices:", error);
    }
  };

  const handleExtractAllPrices = async () => {
    setExtractingAll(true);
    try {
      await extractAllPrices();
      fetchData(
        queryParams +
          (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
      );
      setSuccessMessage("All prices extracted successfully");
    } catch (error) {
      console.error("Error extracting all prices:", error);
    } finally {
      setExtractingAll(false);
    }
  };
  const handleEseguiMatching = async () => {
    setExtractingAll(true);
    try {
      await eseguiAllMatching();
      fetchData(
        queryParams +
          (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
      );
      setSuccessMessage("All prices extracted successfully");
    } catch (error) {
      console.error("Error extracting all prices:", error);
    } finally {
      setExtractingAll(false);
    }
  };

  const handleEseguiInoltroPrezzi = async () => {
    setExtractingAll(true);
    try {
      await eseguiAllInoltroPrezzi();
      fetchData(
        queryParams +
          (orderBy !== null ? `&sort=${orderBy},${orderDirection}` : "")
      );
      setSuccessMessage("All Inoltro Prezzi successfully");
    } catch (error) {
      console.error("Error All Inoltro Prezzi:", error);
    } finally {
      setExtractingAll(false);
    }
  };


  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  interface RowsPerPageChangeEvent extends React.ChangeEvent<HTMLInputElement> {
    target: HTMLInputElement & { value: string };
  }

  const handleChangeRowsPerPage = (event: RowsPerPageChangeEvent) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage(null);
  };

  const handleSort = (property: string) => {
    const isAsc = orderBy === property && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: "100%", maxWidth: { sm: "100%", md: "1700px" } }}>
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
      >
        <ProductsFilter handleFilter={handleFilter} queryParams={queryParams} />
      </Drawer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography component="h2" variant="h6">
          Prodotti
        </Typography>
        <Box>
          {extractingAll ? (
            <CircularProgress size={24} sx={{ ml: 2 }} />
          ) : (
            <IconButton
              title="Esegui Scraping"
              onClick={handleExtractAllPrices}
              color="primary"
            >
              <ScatterPlot />
            </IconButton>
          )}
          {extractingAll ? (
            <CircularProgress size={24} sx={{ ml: 2 }} />
          ) : (
            <IconButton
              title="Esegui Matching"
              onClick={handleEseguiMatching}
              color="primary"
            >
              <Wifi2BarOutlined />
            </IconButton>
          )}
          {extractingAll ? (
            <CircularProgress size={24} sx={{ ml: 2 }} />
          ) : (
            <IconButton
              title="Inoltra prezzi"
              onClick={handleEseguiInoltroPrezzi}
              color="primary"
            >
              <CurrencyExchange />
            </IconButton>
          )}
          <IconButton
            title="Carica File"
            onClick={handleOpenUploadModal}
            color="primary"
          >
            <UploadFile />
          </IconButton>
          <IconButton title="Aggiungi prodotto" onClick={() => handleOpenProdottoForm()} color="primary">
            <AddIcon />
          </IconButton>
          <IconButton title="Aggiorna lista" onClick={() => fetchData(queryParams)} color="primary">
            <RefreshIcon />
          </IconButton>
          <IconButton
            title="Filtra"
            onClick={() => setIsFilterDrawerOpen(true)}
            color="primary"
          >
            <Search />
          </IconButton>
          <Dialog open={openUploadModal} onClose={handleCloseUploadModal}>
            <DialogTitle>Upload File</DialogTitle>
            <DialogContent>
              <TextField
                select
                label="Select Fornitore"
                value={selectedFornitore}
                onChange={(e) => handleFornitoreChange(e.target.value)}
                fullWidth
                margin="normal"
              >
                {fornitori.map((fornitore) => (
                  <MenuItem
                    key={fornitore.id?.toString()}
                    value={fornitore.id?.toString()}
                  >
                    {fornitore.descrizione}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                type="file"
                onChange={handleFileChange}
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseUploadModal}>Cancel</Button>
              <Button onClick={handleUpload} color="primary">
                Upload
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Card sx={{ mb: 2, borderRadius: 2, border: "1px solid #40a7f2" }}>
        <CardContent>
          <TableContainer component={Paper} sx={{ maxHeight: "70vh" }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sortDirection={orderBy === "id" ? orderDirection : false}
                  >
                    <TableSortLabel
                      active={orderBy === "id"}
                      direction={orderBy === "id" ? orderDirection : "asc"}
                      onClick={() => handleSort("id")}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "codice" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "codice"}
                      direction={orderBy === "codice" ? orderDirection : "asc"}
                      onClick={() => handleSort("codice")}
                    >
                      Codice
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "descrizione" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "descrizione"}
                      direction={
                        orderBy === "descrizione" ? orderDirection : "asc"
                      }
                      onClick={() => handleSort("descrizione")}
                    >
                      Descrizione
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "descrizione" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "descrizione"}
                      direction={
                        orderBy === "descrizione" ? orderDirection : "asc"
                      }
                      onClick={() => handleSort("descrizione")}
                    >
                      File
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "prezzo" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "prezzo"}
                      direction={orderBy === "prezzo" ? orderDirection : "asc"}
                      onClick={() => handleSort("prezzo")}
                    >
                      Prezzo
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "disponbilita" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "disponibile"}
                      direction={
                        orderBy === "disponibile" ? orderDirection : "asc"
                      }
                      onClick={() => handleSort("disponibile")}
                    >
                      Disponibile
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>Operazioni</TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "dataIns" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "dataIns"}
                      direction={orderBy === "dataIns" ? orderDirection : "asc"}
                      onClick={() => handleSort("dataIns")}
                    >
                      Data
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    sortDirection={
                      orderBy === "dataUpd" ? orderDirection : false
                    }
                  >
                    <TableSortLabel
                      active={orderBy === "dataUpd"}
                      direction={orderBy === "dataUpd" ? orderDirection : "asc"}
                      onClick={() => handleSort("dataUpd")}
                    >
                      Aggiornamento
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow sx={{ minHeight: "74px" }}>
                    <TableCell colSpan={7} align="center">
                      <CircularProgress />
                      <Typography>Loading...</Typography>
                    </TableCell>
                  </TableRow>
                ) : (
                  prodotti.map((prodotto) => (
                    <TableRow key={prodotto.id}>
                      <TableCell>{prodotto.id}</TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <IconButton
                            color="primary"
                            component={Link}
                            to={`/products/${prodotto.id}`}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDelete(prodotto.id)}
                            color="primary"
                          >
                            <Delete />
                          </IconButton>
                          {extracting[prodotto.id] ? (
                            <CircularProgress size={24} sx={{ ml: 1 }} />
                          ) : (
                            <IconButton
                              color="primary"
                              onClick={() => handleExtractPrices(prodotto.id)}
                            >
                              <AccountBalanceWallet />
                            </IconButton>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{prodotto.codice}</TableCell>
                      <TableCell>{prodotto.descrizione}</TableCell>
                      <TableCell>{prodotto.fileCaricati?.nomeFile}</TableCell>
                      <TableCell>
                        <Euro sx={{ verticalAlign: "middle", mr: 1 }} />
                        {prodotto.prezzo}
                      </TableCell>
                      <TableCell>
                        {prodotto.disponibile ? (
                          <>
                            <Check
                              sx={{
                                verticalAlign: "middle",
                                mr: 1,
                                color: "green",
                              }}
                            />{" "}
                            da {prodotto.numeroFornitoriDisponibile} fornitori
                          </>
                        ) : (
                          <>
                            {" "}
                            <Check
                              sx={{
                                verticalAlign: "middle",
                                mr: 1,
                                color: "red",
                              }}
                            />{" "}
                            "Non disponibile"{" "}
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: "flex" }}>
                          {prodotto.abilitaScraping ? (
                            <ScatterPlot
                              sx={{ color: "green" }}
                              titleAccess="Scraping Attivo"
                            />
                          ) : (
                            ""
                          )}

                          {prodotto.abilitaMatching ? (
                            <Wifi2BarOutlined
                              sx={{ color: "green" }}
                              titleAccess="Matching Attivo"
                            />
                          ) : (
                            ""
                          )}
                          {prodotto.attivo ? (
                            <CurrencyExchange
                              sx={{ color: "green" }}
                              titleAccess="Inoltro Prezzo Attivo"
                            />
                          ) : (
                            ""
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>{prodotto.dataIns}</TableCell>
                      <TableCell>{prodotto.dataUpd}</TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[100, 200, 500]}
            component="div"
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>

      <ProdottoFormDialog
        open={openProdottoForm}
        onClose={handleCloseProdottoForm}
        onSuccess={handleSuccessProdotto}
        prodotto={selectedProdotto ?? undefined}
      />

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Products;
